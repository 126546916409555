import { SET_SEARCH_RESULT, SET_LOADING_STATUS, SET_ALERT_STATUS } from "./actionTypes";

export const set_search_result = content => ({
  type: SET_SEARCH_RESULT,
  payload: {
    search_result: content
  }
});

export const set_loading_status = content => ({
  type: SET_LOADING_STATUS,
  payload: {
    loading_status: content
  }
});

export const set_alert_status = content => ({
  type: SET_ALERT_STATUS,
  payload: {
    alert_status: content
  }
});