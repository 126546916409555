import React, { useEffect, useState, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import { Search } from 'carbon-components-react';
import { client } from '../../helpers/client';
import { set_search_result, set_loading_status, set_alert_status } from '../../redux/actions';

const SearchAutocomplete = ({
    set_search_result,
    set_loading_status,
    set_alert_status
}) => {

    const [suggest_list, setSuggestList] = useState([]);
    const ref = useRef(null);

    const escapeListener = useCallback((e) => {
        if (e.key === 'Escape') {
            onClose()
        }
    }, [])
    const clickListener = useCallback(
        (e) => {
            if(ref.current)
                if (!(ref.current).contains(e.target)) {
                    onClose?.()
            }
        },
        [ref.current],
    )

    const onClose = () => {
        setSuggestList([]);
    }

    useEffect(() => {
        // Attach the listeners on component mount.
        document.addEventListener('click', clickListener)
        document.addEventListener('keyup', escapeListener)
        // Detach the listeners on component unmount.
        return () => {
            document.removeEventListener('click', clickListener)
            document.removeEventListener('keyup', escapeListener)
        }
    }, []);

    const search_patent = async (id) => {
        set_loading_status(true);
        console.log('uuid >>>>', id)

        const detail = await client.post(`/`,
            `{
                "endpoint": "/patent_feature/generate_report",
                "data": {
                    "patent_id": ["`+id+`"]
                }
              }`
        );
        console.log('generate report response >>>', detail)
        set_loading_status(false);
        if (detail.status == '200' && detail.statusText == 'OK' && detail.data != "No route found.") {
            if(detail.data.error) {
                set_alert_status(false); 
                setTimeout(() => { set_alert_status(true); }, 100);
                return;
            }
            let result = detail.data;
            let panel1 = result.panel1;
            let data = [];
            let uuid_arr = Object.keys(panel1);
            for(let index = 0; index < uuid_arr.length; index++) {
                data[index] = {...panel1[uuid_arr[index]], uuid: uuid_arr[index]};
            }
            result.panel1 = data;
            set_search_result(result);
        }
    }

    const search_autocomplete_suggest_content = suggest_list && suggest_list.map((item, index) =>
        (<li className='list-item' onClick={() => { setSuggestList([]); search_patent(item.uuid); }} key={'search_' + index}>{item.title}</li>)
    );

    const do_search = async (val) => {
        if (val == '' || val == undefined || val.length < 3) {
            setSuggestList([]);
            return;
        }
        set_loading_status(true);
        const search_result = await client.post(`/`,
            `{
                "endpoint": "/patentSearchAutocomplete",
                "data": {
                    "query": "` + val + `"
                }
            }`
        );
        console.log('load list of search finished <<<')
        set_loading_status(false);
        if (search_result.status == '200' && search_result.statusText == 'OK' && search_result.data != "No route found.") {
            let result = search_result.data;
            console.log('>?>>>>>>>>>>', result)
            if(!(result instanceof Array)) result = [];
            // result = JSON.parse(result);
            setSuggestList(result);
        }

    }

    return (
        <div ref={ref} className='search-component'>
            <Search
                id="patent-search"
                placeHolderText="Patent Search"
                size='xl'
                labelText=''
                onChange={(e) => { do_search(e.target.value) }}
            />
            {suggest_list.length > 0 && <ul aria-labelledby="search-label" role="menu" id="search-menu" className="GlobalSearch-module--list">
                {search_autocomplete_suggest_content}
            </ul>}
        </div>
    )
}

export default connect(
    null,
    {
        set_search_result,
        set_loading_status,
        set_alert_status
    }
)(SearchAutocomplete);