import React, { useEffect, useState } from 'react';
import Sidebar from '../components/Sidebar';
import HeaderComponent from '../components/Header/index';
import Progressbar from '../components/Progressbar/index';
import { Loading } from 'carbon-components-react';
import { connect } from 'react-redux';

const Dashboard = (props) => {

    const {children, loading_status} = props;

    return (
        <main className='dashboard-container my-dark-theme'>
            {/* <Loading
                description="Loading" withOverlay={true} active={loading_status}
            /> */}
            <Progressbar active={loading_status} />
            <HeaderComponent></HeaderComponent>
            <div id="main-content" className='main-contain'>
                <Sidebar/>
                {children}
            </div>
        </main>
    );
}

const mapStateToProps = ({
    loading_status,
}) => {
    const loading = loading_status.loading_status;
    return { loading_status : loading };
};

export default connect(
    mapStateToProps,
)(Dashboard);