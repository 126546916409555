import React, { useEffect, useState } from 'react';
import { Tag, Dropdown } from 'carbon-components-react';
// import { Sunburst } from 'react-vis';
import Sunburst from '../../components/Sunburst';

import { COLOR_NAMES } from '../../constants/defaultValues';
import AutoSizer from 'react-virtualized-auto-sizer';

const SearchTopicDecomposition = (props) => {

    const sm_items = [
        {
            id: 'option-1',
            label: 'Past 3 years',
        },
        {
            id: 'option-2',
            label: 'Past 2 years',
        },
        {
            id: 'option-3',
            label: 'Past 1 year',
        },
    ];

    const [topics, setTopics] = useState([]);
    const [open_status_discovery_item, setOpenStatusDiscoveryItem] = useState([]);
    const [current_tab, setCurrentTab] = useState(0);
    const [caption_data, setCaptionData] = useState([]);
    const [detail_info, setDetailInfo] = useState('magenta');


    const tile_types = ['magenta', 'green', 'blue', 'purple', 'cyan', 'teal', 'red', 'gray', 'cool-gray', 'warm-gray'];

    useEffect(() => {
        setTopics(props.panel2);
    }, []);

    const updateData = (data, keyPath) => {
        if (data.children) {
            data.children.map(child => updateData(child, keyPath));
        }
        // add a fill to all the uncolored cells
        if (!data.hex) {
            data.style = {
                fill: COLOR_NAMES[Math.floor(Math.random() * COLOR_NAMES.length)]
            };
        }
        data.style = {
            ...data.style,
            fillOpacity: keyPath && !keyPath[data.name] ? 0.2 : 1
        };

        return data;
    }

    const classesCount = (classes, uniqueClasses, cpcCodeDetails) => {
        let uniqueClass = []
        uniqueClasses.forEach(elem => {
            let count = classes.filter(item => item === elem).length;
            uniqueClass.push({ elem, count })
        })
        uniqueClass.sort((a, b) => b.count - a.count);

        // attach description to each class
        uniqueClass.map((uniqueClassItem) => {
            let currentClass = cpcCodeDetails.filter(cpcCode => cpcCode.name === uniqueClassItem.elem)
            uniqueClassItem.description = currentClass[0] ? currentClass[0].description : 'N/A'
        })

        return uniqueClass
    }

    const unique = (value, index, self) => {
        return self.indexOf(value) === index;
    }

    useEffect(() => {
        let temp_arr = [];
        for (let index = 0; index < props.panel3.length; index++) {
            temp_arr[index] = false;
        }
        setOpenStatusDiscoveryItem(temp_arr);

    }, [props.panel3]);

    const getCpcCode = (total_data, result_arr) => {
        if (total_data == undefined) return;
        for (let i = 0; i < total_data.length; i++) {
            let tmp = { name: "", description: "" };
            if (total_data[i].name) tmp.name = total_data[i].name;
            if (total_data[i].description) tmp.description = total_data[i].description;
            result_arr.push(tmp);
            getCpcCode(total_data[i].children, result_arr);
        }
    }

    useEffect(() => {
        let timeline_data = props.timeline_cards;
        let originalClasses = [];
        for (let index = 0; index < timeline_data.length; index++) {
            originalClasses[index] = timeline_data[index].classes;
        }
        let cpcCodeDetails = [];
        getCpcCode(props.sunburst.children, cpcCodeDetails);
        let classes = originalClasses.filter(unique);
        console.log('originalClasses >>>>', originalClasses)
        console.log('classes >>>>', classes)
        console.log('cpcCodeDetails >>>>', cpcCodeDetails)

        let countClasses = classesCount(originalClasses, classes, cpcCodeDetails);
        setCaptionData(countClasses);
        console.log('final >>>>', countClasses)

    }, [props.timeline_cards, props.sunburst]);


    return (
        <div className='bx--row'>
            <div className='bx--col-lg-12'>
                <div className='big-title'>
                    Topic Decomposition
                </div>
                <div className='tab-group'>
                    {props.panel2 && props.panel2.map((item, index) =>
                    (
                        <Tag className='tab-item' key={'tab-tile-' + index} onClick={() => { setCurrentTab(index); setDetailInfo(tile_types[index]) }} type={tile_types[index]}>{item.topic_title}</Tag>
                    )
                    )}
                </div>
                <div className='bx--row p-20'>
                    <div className={'detail-info ' + (props.sunburst ? 'bx--col-lg-6' : 'bx--col-lg-16')}>
                        <Tag type={detail_info}>
                            <ul>
                                {props.panel2.length > 0 && props.panel2[current_tab].topic_sents && props.panel2[current_tab].topic_sents.map((item, index) =>
                                (
                                    <li key={'topic-sent' + current_tab + '-' + index}>{item}</li>
                                )
                                )}
                            </ul>
                        </Tag>
                    </div>
                    {
                        props.sunburst &&
                        <div className='bx--col-lg-10'>
                            <div className='sunburst-contain'>
                                <Sunburst
                                    data={updateData(props.sunburst)}
                                    width="400"
                                    height="400"
                                    count_member="size"
                                    labelFunc={node => {
                                        return node.data.name;
                                    }}
                                />
                            </div>
                            <div className='sunburst-caption'>
                                {caption_data[0] &&
                                    (<div><span className='bold'>{caption_data[0].description}</span> has the highest number of patents associated with this search at <span className='bold'>{caption_data[0].count}</span></div>)}
                                {caption_data[1] &&
                                    (<div><span className='bold'>{caption_data[1].description}</span> has the second highest, with <span className='bold'>{caption_data[1].count}</span></div>)}
                            </div>
                            {/* <div className='graph-detail-item-contain'>
                            <div className='title'>
                                <div>ETH</div>
                                <div className='disable'>Ethereum</div>
                            </div>
                            <div className='change'>
                                <div className='minus'>-$271.07</div>
                                <div className='disable'>-11,50(5,20%)</div>
                            </div>
                            <div className='score'>
                                <div>$3,591.00</div>
                                <div className='disable'>17,181</div>
                            </div>
                        </div>
                        <div className='graph-detail-item-contain'>
                            <div className='title'>
                                <div>LTC</div>
                                <div className='disable'>Litecoin</div>
                            </div>
                            <div className='change'>
                                <div className='plus'>+$505.07</div>
                                <div className='disable'>+5,81(7,10%)</div>
                            </div>
                            <div className='score'>
                                <div>$2,043</div>
                                <div className='disable'>87,579</div>
                            </div>
                        </div>
                        <div className='graph-detail-item-contain'>
                            <div className='title'>
                                <div>ETH</div>
                                <div className='disable'>Ethereum</div>
                            </div>
                            <div className='change'>
                                <div className='minus'>-$271.07</div>
                                <div className='disable'>-11,50(5,20%)</div>
                            </div>
                            <div className='score'>
                                <div>$3,591.00</div>
                                <div className='disable'>17,181</div>
                            </div>
                        </div> */}
                        </div>
                    }
                </div>
            </div>
            <div className='bx--col-lg-4 document-discovery-contain'>
                <div className='big-title'>
                    Document discovery
                </div>
                <div className=''>
                    <Dropdown
                        className='topic-select'
                        ariaLabel="Dropdown"
                        id="doc-discovery-dropdown"
                        items={sm_items}
                        label="Choose"
                    />
                </div>
                <div>
                    {props.panel3 && props.panel3.map((item, index) =>
                    (
                        <div
                            onClick={() => {
                                let temp_arr = open_status_discovery_item;
                                temp_arr[index] = !temp_arr[index];
                                setOpenStatusDiscoveryItem([...temp_arr]);
                            }}
                            key={'topic-card-' + index}
                            className={'doc-discovery-item' + (open_status_discovery_item[index] ? ' opened' : '')}
                        >
                            <span className='bold'>Grant:</span> {item.title} <br />
                            <span className='bold'>Assignee:</span> {item.assignee} <br />
                            {item.summary}
                        </div>
                    )
                    )}
                </div>
            </div>
        </div>
    )

}

export default SearchTopicDecomposition;