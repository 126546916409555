import { SET_LOADING_STATUS } from "../actionTypes";

const initialState = {
  loading_status: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_LOADING_STATUS: {
      const { loading_status } = action.payload;
      return {
        ...state,
        loading_status: loading_status,
      };
    }
    default:
      return state;
  }
}