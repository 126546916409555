import { SET_ALERT_STATUS } from "../actionTypes";

const initialState = {
  alert_status: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_ALERT_STATUS: {
      const { alert_status } = action.payload;
      return {
        ...state,
        alert_status: alert_status,
      };
    }
    default:
      return state;
  }
}