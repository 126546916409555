import React, { useEffect, useState } from 'react';
import SearchAbstract from '../components/SearchAbstract';
import SearchTopicDecomposition from '../components/SearchTopicDecomposition';
import SearchTimelineTab from '../components/SearchTimelineTab';
import { Tabs, Tab, InlineNotification } from 'carbon-components-react';
import { connect } from 'react-redux';
import "@carbon/charts/styles.css";

const SearchContent = ({
    search_results,
    alert_stats
}) => {

    const alert_element = (
        <InlineNotification
            kind="error"
            iconDescription="close"
            subtitle={<span>No patents matched to the inputted patents</span>}
            title=""
        />
    );

    const [notification, setNotification] = useState([]);

    useEffect(() => {
        console.log('alert change >>>>')
        if(alert_stats) 
            setNotification([...notification, alert_element]);
    }, [alert_stats]);

    return (
        <div className="main-content search-page">
            <div className='bx--row main-container'>
                <div className='bx--col-lg-4'>
                    <SearchAbstract
                        data={search_results.panel1[0]}
                    />
                </div>
                <div className='bx--col-lg-12 right-panel'>
                    {
                        notification && notification.map(item => item)
                    }
                    <Tabs scrollIntoView={false}>
                        <Tab
                            href="#"
                            id="tab-topic"
                            label="Topic"
                        >
                            <SearchTopicDecomposition
                                panel2={search_results.panel2}
                                panel3={search_results.panel3}
                                sunburst={search_results.sunburst}
                                timeline_cards={search_results.timeline_cards}
                            />
                        </Tab>
                        <Tab
                            href="#"
                            id="tab-timeline"
                            label="Timeline"
                        >
                            <SearchTimelineTab
                                timeline={search_results.timeline}
                                timeline_cards={search_results.timeline_cards}
                            />
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({
    search_result,
    alert_status
}) => {
    const search_results = search_result.search_result;
    const alert_stats = alert_status.alert_status
    return { search_results, alert_stats };
};

export default connect(
    mapStateToProps,
)(SearchContent);