import React, { useEffect, useState } from 'react';
import { Tabs, Tab } from 'carbon-components-react';
import { SimpleBarChart } from "@carbon/charts-react";
// import "@carbon/charts/styles.css";

const SearchTimelineTab = (props) => {


    const timeline_graph_data_temp = [
        {
            group: "Qty",
            score: "1",
            value: [
                1970,
                1985
            ]
        },
        {
            group: "More",
            score: "2",
            value: [
                1976,
                1999
            ]
        },
        {
            group: "Sold",
            score: "3",
            value: [
                1977,
                1988
            ]
        },
        {
            group: "Restocking",
            score: "4",
            value: [
                1980,
                1993
            ]
        },
        {
            group: "Misc",
            score: "5",
            value: [
                1987,
                2011
            ]
        }
    ];

    const [timeline_graph_data, setTimelineGraphData] = useState(props.timeline);

    const timeline_graph_option = {
        title: "",
        axes: {
            bottom: {
                mapsTo: "value",
                includeZero: false,
            },
            left: {
                mapsTo: "title",
                scaleType: "labels"
            }
        },
        tooltip: {
            enabled: true,
            customHTML: (data, defaultHTML) => {
                console.log('tooltip >>>>', data[0].id)
                return '<div style="padding: 5px;border-bottom: solid 1px #F0F0F0; padding: 5px 2px; font-size: 14px; font-weight: bold;">'+data[0].title+'</div><div style="padding: 5px;border-bottom: solid 1px #F0F0F0; padding: 5px 2px; font-size: 14px; font-weight: bold;">'+data[0].id+'</div>'+defaultHTML
            }
        },
        legend: {
            clickable: true
        },
        height: (timeline_graph_data.length > 6 ? timeline_graph_data.length * 40 : 400) + "px",
    };

    useEffect(() => {
        let timeline_result = [];
        let timeline_data = props.timeline;
        for (let [index, item] of timeline_data.entries()) {
            timeline_result[index] = {
                group: item.topic,
                title: item.title,
                id: item.id,
                score: index + 1,
                value: [
                    item.start == 'None' ? 0 : (new Date(item.start)).getFullYear(),
                    item.end == 'None' ? 0 : (new Date(item.end)).getFullYear()
                ]
            }
        }
        setTimelineGraphData(timeline_result);
    }, [props.timeline])

    return (
        <div>
            <Tabs scrollIntoView={true}>
                <Tab
                    href="#"
                    id="tab-1"
                    label="Timeline View"
                >
                    <div className="some-content">
                        <SimpleBarChart
                            data={timeline_graph_data}
                            options={timeline_graph_option}>
                        </SimpleBarChart>
                    </div>
                </Tab>
                <Tab
                    href="#"
                    id="tab-2"
                    label="Topic View"
                >
                    <div className="topic-view">
                        {props.timeline_cards && props.timeline_cards.map((item, index) => (
                            <div key={'topic-view-item-' + index} className="topic-view-item">
                                <div className='number-topic'>
                                    {item.id}
                                </div>
                                <div className='title-topic'>
                                    {item.title}
                                </div>
                                <div>
                                    {item.description}
                                </div>
                            </div>
                        ))
                        }
                    </div>
                </Tab>
            </Tabs>
        </div>
    )
}

export default SearchTimelineTab;