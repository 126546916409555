import SearchAutocomplete from '../../components/Search';

const SearchAbstract = (props) => {

    return (
        <>
            <SearchAutocomplete />
            <div className='search-result-contain'>
                <div className='top-title'>United States patent <span className='bold font-big-a'>{props.data.id}</span></div>
                <div className='title'>
                    {props.data.title}
                </div>
                <div className='short-description'>Abstract</div>
                <div className='result-item'>{props.data.abstract}</div>
                {/* <div className='result-item'><Tag type="magenta">Retroreflective signage</Tag> articles comprise a colorant layer directly thermally transferred to a top layer of a reretroreflective sheeting, the top layer devoid of </div>
                        <div className='result-item'><Tag type="green">priming treatments</Tag> articles comprise a colorant layer directly thermally transferred to a top layer of a reretroreflective sheeting, the top layer devoid of </div>
                        <div className='result-item'><Tag type="blue">thermal mass transfer</Tag> articles comprise a colorant layer directly thermally transferred to a top layer of a reretroreflective sheeting, the top layer devoid of </div> */}
                <div className='short-description'>Description</div>
                <div className='result-item'>Key points for each topic coming from the description</div>

                <div className='title'>
                    Other insights
                </div>
                <div className='insights-content'>
                    This patent ...
                    <ul>
                        {props.data.insights && props.data.insights.map((item, index) =>
                            (<li key={'insights-item-'+index}>{item}</li>)
                        )}
                    </ul>
                </div>
            </div>
        </>
    )
}

export default SearchAbstract;