import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";

const HomeContent = () => {

    const history = useHistory();

    return (
        <div className="">
            <div className='contain'>
                
            </div>
        </div>
    )
}

export default HomeContent;