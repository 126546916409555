import React, { useEffect, useState } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';

const Progressbar = props => {

    const [current_percentage, setCurrentPercentage] = useState(0);
    const [stop, setStop] = useState(null);
    const [is_loading, setLoading] = useState(false);

    const start_progress = () => {
        let percent = 1;
        let interval = setInterval(() => {
            setCurrentPercentage(percent);
            percent++;
            if(percent == 100)
                exit(interval);
        }, 500);
        setStop(interval);
    }

    const exit = (val) => {
        clearInterval(val);
    }

    const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

    useEffect(() => {
        if(props.active) {
            setLoading(true);
            start_progress();
        } else {
            exit(stop);
            setCurrentPercentage(100);
            setTimeout(() => {
                setLoading(false);
                setCurrentPercentage(0);
            }, 300);
        }
    }, [props.active])

    return (
        <div className={'overlay' + (is_loading ? ' show' : '')}>
            <div className='progressbar-contain'>
                <ProgressBar striped variant="success" now={current_percentage} key={1} label={`${current_percentage}%`} className='progressbar-element' />
            </div>
        </div>
    );
};

export default Progressbar;
